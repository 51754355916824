/* global FB */
import Vue from 'vue'
import App from './App.vue'
import eventBus from './eventBus'

Vue.config.productionTip = false

// Adding google libraries
let googleGsi = document.createElement('script')
googleGsi.src = 'https://accounts.google.com/gsi/client'
googleGsi.async = true
document.head.appendChild(googleGsi)

// Adding facebook libraries
let fbDiv = document.createElement('div')
fbDiv.id = 'fb-root'
document.body.appendChild(fbDiv)

let facebookSdk = document.createElement('script')
facebookSdk.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v20.0'
facebookSdk.crossOrigin = 'anonymous'
facebookSdk.nonce = '9rBUlcrQ'
document.body.appendChild(facebookSdk)

window.fbAsyncInit = function() {
  FB.init({
    appId      : '3084385301701240',
    cookie     : true,  // enable cookies to allow the server to access 
                        // the session
    xfbml      : true,  // parse social plugins on this page
    version    : 'v20.0' // Specify the Graph API version to use
  })
}

// We need to declare the google sign in callback here
// Then, we will emit an event to the event bus
window.signInGoogleCallback = function(userProfile) {
  eventBus.$emit('google-signed-in', userProfile)
}

window.statusChangeCallback = function (response) {
  console.log('statusChangeCallback');
  console.log(response);
  // The response object is returned with a status field that lets the
  // app know the current login status of the person.
  // Full docs on the response object can be found in the documentation
  // for FB.getLoginStatus().
  if (response.status === 'connected') {
    // Logged into your app and Facebook.
    eventBus.$emit('facebook-signed-in', response)
  }
}

window.checkLoginState = function() {
  FB.getLoginStatus(function(response) {
    console.log('Response: ', response)
    window.statusChangeCallback(response);
  });
}

// SCSS files
import './style/main.scss'
import './style/app.scss'

new Vue({
  render: h => h(App),
}).$mount('#app')
