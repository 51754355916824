<template>
    <div class="loading-div-container">
        <div class="load-one"></div>
        <div class="load-two"></div>
        <div class="load-three"></div>
    </div>
</template>

<script>
// Example code taken from
// https://codepen.io/mostafa_abdallah/pen/mdpdgaQ
export default {

}
</script>

<style lang="scss" scoped>
.loading-div-container {
    display: flex;
    position: fixed;
    top: 0; /* This is the height of the topbar */
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;

    div {
        width: 25px;
        height: 25px;
        background-color: #F44A1C;
        margin-left: 5px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        margin-top: calc(50px - 12.5px);
        animation-name: loading;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }
    .load-two {
        animation-delay: 0.4s;
    }
    .load-three {
        animation-delay: 0.8s;
    }
    @keyframes loading {
        to {
            opacity: 0.3;
            transform: translateY(-25px);
            -webkit-transform: translateY(-25px);
            -moz-transform: translateY(-25px);
            -ms-transform: translateY(-25px);
            -o-transform: translateY(-25px);
        }

    }
}
</style>