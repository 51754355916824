<template>
    <div class="login-wrap">
        <div class="login-left">
            <div class="login-left-wrapper">
                <div class="cs-logo">
                    <img src="~@/assets/icons/app-icon.svg">
                </div>
                <div v-if="status == 'sign-in-google'">
                    <div class="welcome">
                        Sign In to ClipMagic
                    </div>

                    <div class="verification-code-description">
                        Click the button below in order to continue the Sign In
                    </div>

                    <div class="socials">
                        <div class="socials-btn google-btn" @click="clickGoogleSignIn" style="width: 400px;">
                            <svg id="google" xmlns="http://www.w3.org/2000/svg" width="17.7" height="18.061" viewBox="0 0 17.7 18.061">
                                <path id="Path_405" data-name="Path 405" d="M139.219,108.607a7.738,7.738,0,0,0-.191-1.846H130.55v3.351h4.977a4.413,4.413,0,0,1-1.846,2.93l-.017.112,2.681,2.077.186.019a8.83,8.83,0,0,0,2.689-6.643" transform="translate(-121.519 -99.376)" fill="#4285f4"/>
                                <path id="Path_406" data-name="Path 406" d="M21.992,163.615a8.608,8.608,0,0,0,5.98-2.187l-2.85-2.208a5.345,5.345,0,0,1-3.131.9,5.437,5.437,0,0,1-5.138-3.753l-.106.009-2.788,2.157-.036.1a9.024,9.024,0,0,0,8.067,4.977" transform="translate(-12.962 -145.553)" fill="#34a853"/>
                                <path id="Path_407" data-name="Path 407" d="M3.893,77.2a5.56,5.56,0,0,1-.3-1.786,5.842,5.842,0,0,1,.291-1.786l-.005-.12L1.056,71.312l-.092.044a9.012,9.012,0,0,0,0,8.108L3.893,77.2" transform="translate(0 -66.379)" fill="#fbbc05"/>
                                <path id="Path_408" data-name="Path 408" d="M21.992,3.492a5.005,5.005,0,0,1,3.492,1.345l2.549-2.488A8.677,8.677,0,0,0,21.992,0a9.024,9.024,0,0,0-8.067,4.977l2.92,2.268a5.459,5.459,0,0,1,5.148-3.753" transform="translate(-12.962)" fill="#eb4335"/>
                            </svg>
                            <div>
                                Google Sign In
                            </div>
                        </div>
                    </div>
                    <br><br><br>
                </div>
                <div v-if="status == 'sign-in-fb'">
                    <div class="welcome">
                        Sign In to ClipMagic
                    </div>

                    <div class="verification-code-description">
                        Click the button below in order to continue the Sign In
                    </div>

                    <div class="socials">
                        <div class="socials-btn facebook-btn" @click="clickFbSign" style="width: 400px;">
                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="28px" height="28px">    <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z" fill="#1877F2"/></svg>
                            <div>
                                Facebook Sign In
                            </div>
                        </div>
                    </div>
                    <br><br><br>
                </div>

                <div v-if="status == 'sign-up'">
                    <div class="welcome">
                        Sign Up to ClipMagic
                    </div>
                    <br><br>
                    <div class="socials">
                        <div class="socials-btn google-btn" @click="clickGoogleSignIn">
                            <svg id="google" xmlns="http://www.w3.org/2000/svg" width="17.7" height="18.061" viewBox="0 0 17.7 18.061">
                                <path id="Path_405" data-name="Path 405" d="M139.219,108.607a7.738,7.738,0,0,0-.191-1.846H130.55v3.351h4.977a4.413,4.413,0,0,1-1.846,2.93l-.017.112,2.681,2.077.186.019a8.83,8.83,0,0,0,2.689-6.643" transform="translate(-121.519 -99.376)" fill="#4285f4"/>
                                <path id="Path_406" data-name="Path 406" d="M21.992,163.615a8.608,8.608,0,0,0,5.98-2.187l-2.85-2.208a5.345,5.345,0,0,1-3.131.9,5.437,5.437,0,0,1-5.138-3.753l-.106.009-2.788,2.157-.036.1a9.024,9.024,0,0,0,8.067,4.977" transform="translate(-12.962 -145.553)" fill="#34a853"/>
                                <path id="Path_407" data-name="Path 407" d="M3.893,77.2a5.56,5.56,0,0,1-.3-1.786,5.842,5.842,0,0,1,.291-1.786l-.005-.12L1.056,71.312l-.092.044a9.012,9.012,0,0,0,0,8.108L3.893,77.2" transform="translate(0 -66.379)" fill="#fbbc05"/>
                                <path id="Path_408" data-name="Path 408" d="M21.992,3.492a5.005,5.005,0,0,1,3.492,1.345l2.549-2.488A8.677,8.677,0,0,0,21.992,0a9.024,9.024,0,0,0-8.067,4.977l2.92,2.268a5.459,5.459,0,0,1,5.148-3.753" transform="translate(-12.962)" fill="#eb4335"/>
                            </svg>
                            <div>
                                Google Sign Up
                            </div>
                        </div>
                        <div class="socials-btn facebook-btn" @click="clickFbSign">
                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="28px" height="28px">    <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z" fill="#1877F2"/></svg>
                            <div>
                                Facebook Sign Up
                            </div>
                        </div>
                    </div>
                    <div class="separator">
                        <div class="separator-line"></div>
                        <div class="separator-text">Or Sign Up with email</div>
                        <div class="separator-line"></div>
                    </div>
                    <div class="first-name-flex">
                        <input v-model="firstName" type="text" placeholder="Enter your First Name">
                        <input v-model="lastName" type="text" placeholder="Enter Your Last Name">
                    </div>
                    <div style="margin-bottom: 0px">
                        <input v-model="emailAddress" type="text" placeholder="Enter your email address...">
                    </div>
                    <div style="margin-bottom: 0px">
                        <input v-model="password" type="password" placeholder="Enter your password...">
                    </div>
                    <div style="margin-bottom: 0px">
                        <input v-model="passwordConfirmation" type="password" placeholder="Confirm your password...">
                    </div>
                    <div>
                        <button ref="actionBtn" class="action-btn" @click="clickSignUp">
                            Sign Up To ClipMagic
                        </button>   
                    </div>
                </div>
                <div v-if="status == 'verification-code'">
                    <div class="get-back-view" @click="status = 'sign-up'">
                        &lt; Get Back
                    </div>
                    <div class="welcome">
                        Verify Email
                    </div>
                    <div class="verification-code-description">Enter the verification code sent to your email address</div>
                    <div style>
                        <input v-model="verificationCode" type="text" placeholder="Enter your verification code...">
                    </div>
                    <div>
                        <button ref="actionBtn" class="action-btn" @click="clickConfirmEmailAddress">
                            Confirm Email Address
                        </button>   
                    </div>
                </div>
                <div v-if="status == 'success-sign-up-integrations'">
                    <div class="welcome">
                        Sign Up Successful
                    </div>
                    <div class="verification-code-description">
                        Your account has been successfully created!
                        <br><br>
                        Your login details will be sent to your inbox shortly. If you signed up using Facebook integration, simply log in with Facebook.
                        <br><br>
                        If you haven't installed the app yet, you can download it by clicking  <span class="link-external-app" @click="clickOpenDownloadPage">HERE</span>.
                    </div>
                </div>
                <div v-if="status == 'success-sign-up-email'">
                    <div class="welcome">
                        Sign Up Successful
                    </div>
                    <div class="verification-code-description">
                        You can now login with your email and password inside the app.
                        <br><br>
                        If you haven't installed the app yet, you can download it clicking <span class="link-external-app" @click="clickOpenDownloadPage">HERE</span>.
                    </div>
                </div>
                <div v-if="status == 'success-and-redirect'">
                    <div class="welcome">
                        Login Successful
                    </div>
                    <div class="verification-code-description">
                        You will be redirected to the ClipMagic app.
                        <br><br>
                        If the redirection popup does not open, please click the button below.
                    </div>
                    <div>
                        <button ref="actionBtn" class="action-btn" @click="goToRedirectUrl">
                            Go to ClipMagic
                        </button>   
                    </div>
                </div>
                <div class="need-help" @click="clickOpenSupportPage">
                    <span>Need Help? Get in touch with our support team</span>
                </div>
                <div class="error-message">
                    {{errorMessage}}
                </div>
            </div>
        </div>
        <div class="login-right">
            <img src="~@/assets/login/login-image-2.png">
        </div>
        <div class="socials">
            
        </div>

        <loading-popup v-if="showLoading"></loading-popup>

    </div>
</template>

<script>
/* global FB */
// import eventBus from './eventBus'
import axios from 'axios'
import LoadingPopup from './components/LoadingPopup.vue';

const API_BASE_URL = 'https://api.clipmagic.com'
// const API_BASE_URL = 'https://localhost:3000'

export default {
    name: 'App',
    data() {
        return {
            showLoading: false,
            showGoogle: false,
            showFacebook: false,
            loginButtonUrl: '',
            errorMessage: '',
            status: 'sign-up',
            firstName: '',
            lastName: '',
            emailAddress: '',
            password: '',
            passwordConfirmation: '',
            verificationCode: '',
            redirectToApp: false,
        }
    },
    components: {
        // HelloWorld,
        LoadingPopup,
    },
    created() {
        const urlParams = new URLSearchParams(window.location.search);
        const loginOption = urlParams.get('login');
        const redirectToApp = urlParams.get('redirect');

        if (loginOption == 'google') {
            this.status = 'sign-in-google'
            this.redirectToApp = true
        }
        else if (loginOption == 'fb') {
            this.status = 'sign-in-fb'
            this.redirectToApp = true
        }
        else {
            this.status = 'sign-up'
        }

        if (redirectToApp) {
            this.redirectToApp = true
        }
    },
    mounted() {
        // let refId = this.getCookie('referral')
        // console.log('Referral code: ', refId)

        // eventBus.$on('google-signed-in', this.signInGoogleCallback)
        // eventBus.$on('facebook-signed-in', this.facebookSignInCallback)
    },
    beforeDestroy() {
        // eventBus.$off('google-signed-in', this.signInGoogleCallback)
        // eventBus.$off('facebook-signed-in', this.facebookSignInCallback)
    },
    methods: {
        clickGoogleSignIn() {
            if (!this.googleClient) {
                this.googleClient = window.google.accounts.oauth2.initTokenClient({
                    client_id: '332718262847-2jqjutpqflt4j8i74r95pcs6a9g9ra5q.apps.googleusercontent.com',
                    scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
                    callback: this.signInGoogleCallback,
                })
            }

            this.googleClient.requestAccessToken()
        },
        async signInGoogleCallback(userProfile) {
            try {
                this.showLoading = true
                // Now we're going to get the button for the element information
                let referralCode = this.getCookie('referral')

                let googleSignUrl = `${API_BASE_URL}/v1/users/google-login-request`
                let res = await axios({
                    method: 'post',
                    url: googleSignUrl,
                    data: {
                        credential: userProfile.access_token,
                        refId: referralCode,
                    },
                    timeout: 10000,
                })
                this.showLoading = false

                // Now we're going to generate the redirection
                // console.log('Redirection url: ', redirectionUrl)
                
                if (this.redirectToApp) {
                    this.loginButtonUrl = res.data.redirectionUrl
                    this.status = 'success-and-redirect'
                    window.location.href = this.loginButtonUrl
                }
                else {
                    this.status = 'success-sign-up-integrations'
                    this.errorMessage = ''
                }
            }
            catch (ex) {
                // TODO: Handle Network Requests
                console.error('There was an exception in signInGoogleCallback', ex)
                if (ex.response && ex.response.status == 400) {
                    this.errorMessage = ex.response.data
                }
                else {
                    this.errorMessage = 'An error occurred. Please try again later.'
                }

                this.showLoading = false
            }
        },
        clickFbSign() {
            FB.login((data) => {
                console.log('Response with data...', data)
                if (data.status != 'connected') {
                    // Does nothing 
                    return
                }

                if (!data.authResponse.grantedScopes.includes('email')) {
                    this.errorMessage = 'Email permissions not granted. Please provide email permission to continue'
                    return
                }

                this.showLoading = true
                let referralCode = this.getCookie('referral')

                // Now we're going to get the button for the element information
                let fbSignUrl = `${API_BASE_URL}/v1/users/fb-login-request`
                axios({
                    method: 'post',
                    url: fbSignUrl,
                    data: {
                        ...data,
                        refId: referralCode,
                    },
                    timeout: 10000,
                })
                .then(res => {
                    this.showLoading = false
  
                    if (this.redirectToApp) {
                        let redirectionUrl = res.data.redirectionUrl
                        this.loginButtonUrl = redirectionUrl
                        window.location.href = redirectionUrl

                        this.status = 'success-and-redirect'
                    }
                    else {
                        this.status = 'success-sign-up-integrations'
                        this.errorMessage = ''
                    }
                })
                .catch(ex => {
                    console.error('There was an exception in signInGoogleCallback', ex)
                    if (ex.response && ex.response.status == 400) {
                        this.errorMessage = 'Error in login request: ' + ex.response.data
                    }
                    else {
                        this.errorMessage = 'An error occurred. Please try again later or contact support.'
                    }

                    this.showLoading = false
                })
            }, {
                scope: 'public_profile, email',
                return_scopes: true,
                auth_type: 'rerequest'
            })
        },
        goToRedirectUrl() {
            window.location.href = this.loginButtonUrl
        },
        clickOpenSupportPage() {
            // Open support page in a new tab
            window.open('https://support.clipmagic.com', '_blank')
        },
        async clickSignUp() {
            if (!this.firstName) {
                this.errorMessage = 'First Name is required'
                console.log('Hello')
                return
            }
            if (!this.lastName) {
                this.errorMessage = 'Last Name is required'
                return
            }
            if (!this.emailAddress) {
                this.errorMessage = 'Email Address is required'
                return
            }

            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(this.emailAddress)) {
                this.errorMessage = 'Invalid email address';
                return;
            }
            if (!this.password) {
                this.errorMessage = 'Password is required'
                return
            }
            if (this.password.length < 6) {
                this.errorMessage = 'Password must be at least 6 characters'
                return
            }
            if (this.password != this.passwordConfirmation) {
                this.errorMessage = 'Passwords do not match'
                return
            }

            // All the verifications have succeeded
            // We're going to call the sign up endpoint
            try {
                this.showLoading = true
                await axios({
                    method: 'post',
                    url: `${API_BASE_URL}/v1/users/create-user`,
                    data: {
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.emailAddress,
                        password: this.password,
                    },
                    timeout: 10000,
                })

                this.verificationCode = ''
                this.status = 'verification-code'
                this.errorMessage = ''
                this.showLoading = false
            }
            catch(ex) {
                this.showLoading = false
                console.error('There was an exception in clickSignUp', ex)

                if (ex.response && ex.response.status == 400) {
                    this.errorMessage = 'Error registering user: ' + ex.response.data
                }
                else {
                    this.errorMessage = 'There was an error registering the user. Please try again later or contact support.'
                }
            }
        },
        async clickConfirmEmailAddress() {
            if (!this.verificationCode) {
                this.errorMessage = 'Verification code is required'
                return
            }

            try {
                // We're going to generate the axios code
                this.showLoading = true
                let referralCode = this.getCookie('referral')
                
                let res = await axios({
                    method: 'post',
                    url: `${API_BASE_URL}/v1/users/confirm-user`,
                    data: {
                        email: this.emailAddress,
                        verificationCode: this.verificationCode,
                        refId: referralCode,
                    },
                    timeout: 10000,
                })

                // // Now we're going to generate the redirection
                // let redirectionUrl = res.data.redirectionUrl
                // console.log('Redirection url: ', redirectionUrl)
                if (this.redirectToApp) {
                    let redirectionUrl = res.data.redirectionUrl
                    this.loginButtonUrl = redirectionUrl
                    window.location.href = redirectionUrl

                    this.status = 'success-and-redirect'
                }
                else {
                    this.status = 'success-sign-up-email'
                }
                this.showLoading = false
            }   
            catch(ex) {
                this.showLoading = false
                console.error('There was an exception in checking verification code', ex)

                if (ex.response && ex.response.status == 400) {
                    this.errorMessage = 'Error confirming email: ' + ex.response.data
                }
                else {
                    this.errorMessage = 'There was an error verifying the email address. Please try again later or contact support.'
                }
            }
        },
        clickOpenDownloadPage() {
            // Open download page in a new tab
            window.open('https://download.clipmagic.com/', '_blank')
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
            return null;
        },
    },
}
</script>

<style lang="scss" scoped>

.login-wrap {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    // -webkit-app-region: drag;
    // position: absolute;
    top: 0;
    left: 0;
    // right: 0;
    // bottom: 0;
    width: 100%;
    height: 100%;
    font-family: 'Helvetica Neue';
    display: flex;
    background: white;
}

.login-left {
    width: 600px;
    max-width: 100%;
    height: 100%;
    padding: 20px 60px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-left-wrapper {
    width: 100%;
    input[type=text], input[type=password], select {
        background-color: transparent;
        border: none;
        padding: 12px 20px 12px 20px;
        margin-bottom: 10px;
        font-size: 16px;
        border-radius: 16px;
        width: 100%;
        transition: all 0.15s linear;
        text-align: center;
        border: 2px solid #F2F3F5;
        box-shadow: 0px 1px 1px rgb(75, 73, 88, 0.01);
        color: black;
        letter-spacing: -1px;
    }

    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #A6A4AF;
        opacity: 1; /* Firefox */
        letter-spacing: -1px;
        font-weight: 500;
    }

    .welcome { 
        font-size: 32px;
        font-weight: bold;
        color: black;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        letter-spacing: -1.5px;
    }
    
    .cs-logo {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        img {
            width: 120px;
        }
    }
    
    .already-account {
        font-size: 18px;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        color: #989898;

        .go-sign-in {
            cursor: pointer;
            color: #05A7FC
        }

        .go-sign-up {
            cursor: pointer;
            color: #05A7FC
        }
    }
    
    .already-account-bottom {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        color: #e59bfb;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.3px;

        .go-sign-in {
            cursor: pointer;
            color: #05A7FC
        }

        .go-sign-up {
            cursor: pointer;
            color: #05A7FC
        }
    }

    .or-separator {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        color: #fff;
        font-size: 15px;

        .or-line {
            display: inline-block;
            width: 150px;
            border-top: 1px solid #404040;
        }
    }

    .socials {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .socials-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            vertical-align: middle;
            margin-bottom: 10px;
            // width: 230px;
            width: 100%;
            color: black;
            height: 45px;
            border-radius: 12px;
            font-size: 18px;
            letter-spacing: -0.6px;
            font-weight: normal;
            text-align: center;
            cursor: pointer;
            transition: all 0.15s linear;
            border: 1px solid #F2F3F5;
            box-shadow: 0px 1px 1px rgb(75, 73, 88, 0.1);
        }

        .google-btn {
         
            .google-logo {
                margin-right: 10px;
                vertical-align: middle;
            }

            span {
                vertical-align: middle;
            }
    
            &:hover {
                opacity: 0.8;
            }
        }
    
        .facebook-btn {    
            &:hover {
                opacity: 0.8;
            }
    
            .fb-logo {
                margin-right: 10px;
                vertical-align: text-top;
                height: 20px;
            }

            span {
                vertical-align: middle;
            }
        }
    }


    .full-width {
        width: 100%
    }
    
    .names-wrapper {
        display: flex;
    }

    .terms-wrapper {
        font-size: 14px;
        display: flex;
        align-items: left;
        justify-content: left;
        margin-bottom: 30px;
        margin-top: 10px;
        color: #989898;

        .external-link {
            cursor: pointer;
            color: #05A7FC;
            transition: all 0.15s linear;

            &:hover {
                color: lighten(#05A7FC, 10%);
            }
        }

        // input {
        //     margin-right: 5px;
        //     background-color: #2E2E2E;
        // }

        input {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;
        }

        label {
            position: relative;
            cursor: pointer;
        }

        label:before {
            content:'';
            -webkit-appearance: none;
            appearance: none;
            background-color: #2E2E2E;
            border-radius: 5px;
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 10px;
        }

        input:checked + label:after {
            content: "";
            display: block;
            position: absolute;
            top: 2px;
            left: 6px;
            width: 6px;
            height: 10px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }

    .action-btn {
        width: 100%;
        background-color: #F44A1C;
        border: none;
        border-radius: 16px;
        height: 48px;
        color: white;
        font-size: 18px;
        margin-bottom: 10px;
        transition: all 0.15s linear;
        margin-top: 15px;
        margin-bottom: 15px;
        font-weight: 600;
        cursor: pointer;

        &:hover {
            background-color: lighten(#F44A1C, 5%);
        }
    }

    .action-btn:disabled {
        opacity: 0.5;
    }

    .forgot-password {
        display: flex;
        justify-content: center;
        cursor: pointer;
        color: #AEB5C1;
        margin-top: 10px;
        font-size: 16px;
        transition: all 0.15s linear;
        letter-spacing: -0.5px;
        font-weight: 400;

        &:hover {
            color: lighten(#8B8B8B, 10%);
        }

        img {
            margin-right: 6px;
        }
    }

    .need-help {
        @extend .forgot-password;
        margin-top: 20px;
    }
    
    .error-message {
        margin-top: 20px;
        text-align: center;
        color: #F44A1C;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.3px;
    }

    .login-label-div {
        color: rgba(255, 255, 255, 1.0);
        border-radius: 6px;
        padding: 20px 20px 20px 20px;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 18px;
        width: 100%;
        font-weight: normal;
        text-align: center;
    }
}

.login-right {
    background-color: #41358A;
    padding: 30px;
    width: 1000px;
    flex-grow: 1.2;
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 150%;
        height: 150%;
        min-width: 800px;
        object-fit: contain;
        transform: translate(-50%, -50%);
    }
}

.pwd-wrap {
    position: relative;

    .pwd-eye {
        position: absolute;
        top: 17px;
        left: -27px;
        cursor: pointer;
        opacity: 0.8;
        transition: all 0.15s linear;

        &:hover {
            opacity: 1;
        }

        img {
            height: 12px;
        }
    }
}

.sent-email-to {
    margin-top: 15px;
    text-align: center;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 25px;

    .email-to {
        font-weight: bold;
    }
}

.separator {
    display: flex;
    align-items: center;
    width: 100%;
    color: #AEB5C1;
    gap: 5px;
    margin-top: 15px;
    margin-bottom: 24px;

    .separator-line {
        border-top: 1px solid #e4e5e7;
        box-shadow: 0px 0px 1px rgb(75, 73, 88, 0.1);
        width: 100%;
        flex-grow: 2;
        min-width: 1px;
    }
    .separator-text {
        flex-shrink: 0;
        font-weight: normal;
        font-size: 14px;
    }
}

.first-name-flex {
    display: flex;
    gap: 10px;
}

.verification-code-description {
    font-size: 18px;
    color: black;
    font-weight: 400;
    margin: 30px 0px;
    line-height: 1.5;
    padding: 0px 20px;
}


.link-external-app {
    text-decoration: underline;
    color: #F44A1C;
    cursor: pointer;
}

.get-back-view {
    color: #F44A1C;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    transition: all 0.15s linear;

    &:hover {
        filter: brightness(1.2);
    }
}

@media only screen and (max-width: 900px) {
    .login-right {
        display: none;
    }

    .login-left {
        margin: 0 auto;
        padding: 20px 20px;
        width: 500px;
    }

    .login-left-wrapper {
        input::placeholder {
            font-size: 14px;
        }

        .cs-logo {
            img {
                width: 76px;
            }
        }
        .need-help {
            margin-top: 4px;
            margin-bottom: 30px;
        }
    }
}

</style>
